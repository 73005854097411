/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


      $('.dropdown-toggle').click(function() {
          var location = $(this).attr('href');
          window.location.href = location;
          return false;
      });


        var myLatlng = new google.maps.LatLng(-26.0121718,27.9940777,21),
        mapOptions = {
            zoom: 17,
            center: myLatlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map(document.getElementById('map'), mapOptions);
        var contentString = 'Life Fourways Hospital, Suite A9, Corner Cedar Road & Cedar Avenue West, Fourways, 2055';
        var infowindow = new google.maps.InfoWindow({
          content: contentString,
          maxWidth: 250
        });

        var marker = new google.maps.Marker({
          position: myLatlng,
          map: map
        });

        google.maps.event.addListener(marker, 'click', function() {
          infowindow.open(map,marker);
        });

        google.maps.event.addDomListener(window, "resize", function() {
          var center = map.getCenter();
          google.maps.event.trigger(map, "resize");
          map.setCenter(center);
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        if($(window).width() <= 543) {
          
          $(".appointment.form .gform_wrapper .gfield_date_dropdown_day, .appointment.form .gform_wrapper .gfield_date_dropdown_month").removeClass("col-xs-3 col-sm-3 col-md-3");
          $(".appointment.form .gform_wrapper .gfield_date_dropdown_year").removeClass("col-xs-6 col-sm-6 col-md-6");

          $(".appointment.form .gform_wrapper .gfield_date_dropdown_day, .appointment.form .gform_wrapper .gfield_date_dropdown_month").addClass("col-xs-4 col-sm-4 col-md-4");
          $(".appointment.form .gform_wrapper .gfield_date_dropdown_year").addClass("col-xs-4 col-sm-4 col-md-4");
        
        } else {
          
          $(".appointment.form .gform_wrapper .gfield_date_dropdown_day, .appointment.form .gform_wrapper .gfield_date_dropdown_month").removeClass("col-xs-4 col-sm-4 col-md-4");
          $(".appointment.form .gform_wrapper .gfield_date_dropdown_year").removeClass("col-xs-4 col-sm-4 col-md-4");

          $(".appointment.form .gform_wrapper .gfield_date_dropdown_day, .appointment.form .gform_wrapper .gfield_date_dropdown_month").addClass("col-xs-3 col-sm-3 col-md-3");
          $(".appointment.form .gform_wrapper .gfield_date_dropdown_year").addClass("col-xs-6 col-sm-6 col-md-6");
        
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        $(window).resize(function(){ 
          if($(window).width() <= 543) {
          
            $(".appointment.form .gform_wrapper .gfield_date_dropdown_day, .appointment.form .gform_wrapper .gfield_date_dropdown_month").removeClass("col-xs-3 col-sm-3 col-md-3");
            $(".appointment.form .gform_wrapper .gfield_date_dropdown_year").removeClass("col-xs-6 col-sm-6 col-md-6");

            $(".appointment.form .gform_wrapper .gfield_date_dropdown_day, .appointment.form .gform_wrapper .gfield_date_dropdown_month").addClass("col-xs-4 col-sm-4 col-md-4");
            $(".appointment.form .gform_wrapper .gfield_date_dropdown_year").addClass("col-xs-4 col-sm-4 col-md-4");
          
          } else {
            
            $(".appointment.form .gform_wrapper .gfield_date_dropdown_day, .appointment.form .gform_wrapper .gfield_date_dropdown_month").removeClass("col-xs-4 col-sm-4 col-md-4");
            $(".appointment.form .gform_wrapper .gfield_date_dropdown_year").removeClass("col-xs-4 col-sm-4 col-md-4");

            $(".appointment.form .gform_wrapper .gfield_date_dropdown_day, .appointment.form .gform_wrapper .gfield_date_dropdown_month").addClass("col-xs-3 col-sm-3 col-md-3");
            $(".appointment.form .gform_wrapper .gfield_date_dropdown_year").addClass("col-xs-6 col-sm-6 col-md-6");
          
          }
        });
        
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
